import React from "react"
import StyledHomeBackgroundSection from "../components/backgroundImages/HomeBackgroundSection"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import "./index.scss"

const IndexPage = () => (
  <Layout>
    {/* <StyledHomeBackgroundSection
      style={{
        background:
          "background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) no-repeat center center, background-size: cover",
      }}
    > */}
    <SEO title="Home" />
    <div id="homeContainer">
      <h1 id="homeText">SEKKEI</h1>
      <p id="homeDescription">
        We’re SEKKEI, a team of young, creative, and lively individuals whose
        unique perspective and designs will upgrade your brand to the next level
        -- and bring the future a little bit closer.
      </p>
    </div>
    {/* </StyledHomeBackgroundSection> */}
  </Layout>
)

export default IndexPage
